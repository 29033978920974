.page_bg_item{
  opacity: 0;
  transition: linear .5s;
}

.page_bg_item.active{
  opacity: 1;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}