.page_bg_wrap_anim{
  background-color: #71336B;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
}

.page_bg_wrap_anim:before{
  content: '';
  display: block;
  width: 100%;
  height: 80vh;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #71336B 13%, rgba(113, 51, 107, 0) 100%);
  z-index: 3;
}

.anim_item{
  border: 9px solid;
  border-radius: 8px;
  position: absolute;
  top: 100%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 2;
}

.anim_item.big{
  width: 275px;
}

.anim_item.middle{
  width: 196px;
}

.anim_item.small{
  width: 166px;
}

.anim_item_name{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 136, 49, 0.6);
  border-radius: 8px 8px 0 0;
  font-weight: bold;
  color: #fff;
}

.anim_item.big .anim_item_name{
  height: 52px;
  font-size: 28px;
}

.anim_item.middle .anim_item_name{
  height: 38px;
  font-size: 22px;
}

.anim_item.small .anim_item_name{
  height: 34px;
  font-size: 16px;
}

.anim_item img{
  width: 100%;
}

.anim_item.i1{
  left: 35px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 20s;
  //animation-delay: 1s;
}
.anim_item.i2{
  left: 345px;
  animation-name: big_item;
  border-color: #FF8831;
  animation-duration: 16s;
  animation-delay: 2s;
}
.anim_item.i3{
  left: 545px;
  animation-name: big_item;
  border-color: rgba(255, 255, 255, 0.6);
  animation-duration: 18s;
  animation-delay: 3s;
}
.anim_item.i4{
  left: 780px;
  animation-name: big_item;
  border-color: #FFC52E;
  animation-duration: 20s;
  animation-delay: 1s;
}
.anim_item.i5{
  left: 1090px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 16s;
  animation-delay: 4s;
}
.anim_item.i6{
  left: 1290px;
  animation-name: big_item;
  border-color: #FF8831;
  animation-duration: 18s;
  animation-delay: 5s;
}
.anim_item.i7{
  left: 1525px;
  animation-name: big_item;
  border-color: rgba(255, 255, 255, 0.6);
  animation-duration: 20s;
  animation-delay: 2s;
}
.anim_item.i8{
  left: 345px;
  animation-name: big_item;
  border-color: #FFC52E;
  animation-duration: 8s;
  animation-delay: 7s;
}
.anim_item.i9{
  left: 545px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 18s;
  animation-delay: 12s;
}
.anim_item.i10{
  left: 35px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 20s;
  animation-delay: 10s;
}
.anim_item.i11{
  left: 1090px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 8s;
  animation-delay: 9s;
}
.anim_item.i12{
  left: 1290px;
  animation-name: big_item;
  border-color: #FF8831;
  animation-duration: 18s;
  animation-delay: 14s;
}
.anim_item.i13{
  left: 1525px;
  animation-name: big_item;
  border-color: #F7523B;
  animation-duration: 20s;
  animation-delay: 12s;
}

.anim_item.i1 .anim_item_name,
.anim_item.i5 .anim_item_name,
.anim_item.i9 .anim_item_name,
.anim_item.i13 .anim_item_name{
  background: rgba(247, 82, 59, 0.6);
}
.anim_item.i2 .anim_item_name,
.anim_item.i6 .anim_item_name,
.anim_item.i10 .anim_item_name{
  background: rgba(255, 136, 49, 0.6);
}
.anim_item.i3 .anim_item_name,
.anim_item.i7 .anim_item_name,
.anim_item.i11 .anim_item_name{
  background: rgba(198, 173, 196, 0.6);
}
.anim_item.i4 .anim_item_name,
.anim_item.i8 .anim_item_name,
.anim_item.i12 .anim_item_name{
  background:rgba(255, 196, 46, 0.6);
}

@keyframes big_item {
  0%{
    transform: translateY(0);
  }

  100%{
    transform: translateY(calc(-100vh - 300px));
  }
}

.page_bg_wrap_anim li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: li_animate 25s linear infinite;
  bottom: -150px;
  z-index: 0;

}

.page_bg_wrap_anim li:nth-of-type(1){
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
}

.page_bg_wrap_anim li:nth-of-type(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.page_bg_wrap_anim li:nth-of-type(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.page_bg_wrap_anim li:nth-of-type(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.page_bg_wrap_anim li:nth-of-type(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.page_bg_wrap_anim li:nth-of-type(6){
  left: 75%;
  width: 25px;
  height: 25px;
  animation-delay: 3s;
}

.page_bg_wrap_anim li:nth-of-type(7){
  left: 35%;
  width: 15px;
  height: 15px;
  animation-delay: 7s;
}

.page_bg_wrap_anim li:nth-of-type(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.page_bg_wrap_anim li:nth-of-type(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.page_bg_wrap_anim li:nth-of-type(10){
  left: 85%;
  width: 25px;
  height: 25px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes li_animate {
  0%{
    transform: translateY(0);
    opacity: 1;
    border-radius: 0;
  }

  100%{
    transform: translateY(-1000px);
    opacity: 0;
    border-radius: 50%;
  }
}

.video_bg_wrap{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 153px);
  background-color: #71336B;
  z-index: 0;
}

.video_bg_player{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 0;
}

.video_bg_blur{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(51px);
  backdrop-filter: blur(51px);
  transition: width .3s;
}

.video_bg_blur.bl_2{
  width: calc(100% - 16.9%);
}
.video_bg_blur.bl_3{
  width: calc(100% - 34%);
}
.video_bg_blur.bl_4{
  width: calc(100% - 49.1%);
}
.video_bg_blur.bl_5{
  width: calc(100% - 60.6%);
}
.video_bg_blur.bl_6{
  width: calc(100% - 73.2%);
}
.video_bg_blur.bl_7{
  width: calc(100% - 86.5%);
}
.video_bg_blur.bl_8{
  width: 0;
}

.page_content{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page_header{
  width: 100%;
  position: relative;
  z-index: 2;
}

.page_header_top{
  padding: 0 60px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #71336B;
}

.header_logo{
  height: 50px;
}

.page_header_google_btn{
  width: 230px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: .3s;
}

.page_header_google_btn:hover{
  background-color: #fff;
  color: #333;
}

.page_header_google_btn img{
  margin-right: 12px;
}

.page_copy{
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 9px 30px;
  background: rgba(75, 95, 113, 0.6);
  border-radius: 0 6px 0 0;
  font-size: 12px;
  color: #fff;
  z-index: 4;
}

.page_copy span{
  text-decoration: underline;
  cursor: pointer;
}

.page_copy a{
  color: #fff;
}

.page_policy{
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 63px;
  padding: 28px 21px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px 10px 0 0;
  z-index: 3;
}

.page_policy_text{
  color: #000;
}

.page_policy_link{
  width: max-content;
  margin: 10px auto 0;
  line-height: 1;
  text-decoration: underline;
  cursor: pointer;
}

.page_policy_link a {
  color: #E55535;
}

.page_policy_close{
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
  width: max-content;
  cursor: pointer;
  z-index: 3;
}

.block_wrap{
  flex: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: 90px;
}

.block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  z-index: 2;
}

.page_block_1{
  margin: auto;
  text-align: center;
  z-index: 1;
  position: relative;
}

.page_big_title{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 72px;
  color: #FFFFFF;
  text-shadow: 0 4px 13px rgba(65, 3, 60, 0.55);
  margin: 0 auto;
}

.page_start_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
  max-width: 620px;
  margin: 21px auto 0;
}

.btn{
  font-family: "Roboto", sans-serif;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: bold;
  font-size: 26px;
  color: #2D2D2D;
  cursor: pointer;
  max-width: 100%;
}

.fill{
  background-color: #FEB930;
  transition: box-shadow .5s;
}

.btn:hover{
  box-shadow: 0 8px 25px rgba(0,0,0,.47);
}

.stroke{
  border: 2px solid #FEB930;
  color: #fff;
}

.page_start_btn{
  width: 361px;
  margin: 39px auto 40px;
}

.page_start_reg_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  margin: 0 auto;
}

.page_start_reg_text span{
  text-transform: uppercase;
  font-size: 24px;
}

.page_quest_buttons_row{
  display: flex;
  align-items: center;
  margin-top: 27px;
}

.page_quest_buttons_row > div,
.page_reg_input_row .btn{
  width: 254px;
}

.page_skip_btn{
  margin-left: 15px;
}

.page_quest_title{
  font-family: "Roboto", sans-serif;
  font-size: 45px;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 27px;
}

.page_quest_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  max-width: 546px;
}

.text_quest_4{
  font-size: 30px;
}

.page_quest_checks_wrap{
  margin-top: 40px;
  margin-bottom: 8px;
}

.page_quest_checks_row{

}

.page_quest_checks_row + .page_quest_checks_row{
  margin-top: 20px;
}

.page_quest_checks_row input{
  display: none;
}

.page_quest_checks_row label{
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
}

.page_quest_checks_row label:before{
  content: '';
  display: block;
  width: 33px;
  height: 33px;
  background-color: #fff;
  border: 2px solid #DADDE1;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 28px;
  background-position: center;
  background-repeat: no-repeat;
}

.page_quest_checks_row input:checked + label:before{
  border-color: #fff;
  background-image: url(../../img/lnd31/check_blue.svg);
}

.page_reg_text{
  font-family: "Roboto", sans-serif;
  max-width: 520px;
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.page_reg_text_last{
  max-width: 450px;
  margin-bottom: 30px;
}

.page_reg_input_row{
  display: flex;
  align-items: center;
  margin-top: 31px;
}

.page_reg_box_input{
  position: relative;
}

.page_reg_input_wrap span,
.page_reg_box_input span{
  position: absolute;
  bottom: -25px;
  left: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

.page_reg_input{
  display: block;
  font-family: "Roboto", sans-serif;
  width: 428px;
  height: 62px;
  border: 2px solid #FFFFFF;
  border-radius: 6px;
  background-color: transparent;
  margin-right: 15px;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  text-align: left;
}

.page_reg_input.input_name{
  margin-bottom: 0;
}

.page_reg_input.error{
  border: 2px solid #E55535;
}

.page_reg_input.input_num{
  text-align: center;
}

.page_reg_input:hover,
.page_reg_input:focus{
  border-color: #fff;
}

.page_reg_input::placeholder{
  text-align: left;
  color: #fff;
}

.page_reg_input_wrap{
  position: relative;
  margin-top: 30px;
  margin-bottom: 35px;
  width: 428px;
}

.page_reg_input_wrap:not(.input_name) .page_reg_input:first-child{
  margin-bottom: 19px;
}

.page_find_btn{
  width: 428px;
}

.steps_wrap{
  display: flex;
}

.step_item{
  font-family: "Roboto", sans-serif;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #FF9BF7;
  background-color: #8D1884;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.step_item:not(:first-child){
  margin-left: -19px;
}

.step_item:not(.step_7){
  clip-path: polygon(0 0, calc(100% - 18px) 0, 100% 50%, calc(100% - 18px) 100%, 0% 100%, 18px 50%);
}

.step_item.step_7{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100% , 18px 50%);
}

.step_item:first-child{
  clip-path: polygon(0 0, calc(100% - 18px) 0, 100% 50%, calc(100% - 18px) 100%, 0% 100%);
}

.step_item.active{
  color: #fff;
}

.step_item span{
  display: none;
  margin-right: 5px;
}

.step_item.active span{
  display: block;
}

.step_item:not(.step_7) svg{
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  fill: none;
  stroke: #C541BB;
}

.step_item.active ~ .step_item{
  color: #380034;
  background-color: #67005F;
}

.step_item.active ~ .step_item svg,
.step_item.active svg{
  stroke: #380034;
}

.step_item.step_7 svg{
  fill: none;
  stroke: #fff;
}

.step_item:nth-child(1){
  width: calc(16.9% + 19px);
}
.step_item:nth-child(2){
  width: calc(17.1% + 19px);
}
.step_item:nth-child(3){
  width: calc(15.1% + 19px);
}
.step_item:nth-child(4){
  width: calc(11.5% + 19px);
}
.step_item:nth-child(5){
  width: calc(12.6% + 19px);
}
.step_item:nth-child(6){
  width: calc(13.3% + 19px);
}
.step_item:nth-child(7){
  width: 13.5%;
}

@media screen and (max-width: 1399px){
  .page_bg_item.bg7{
    background-position: left center;
  }

  .page_bg_item.bg2{
    background-position: right center;
  }

  .page_bg_item.bg5{
    background-position: left center;
    background-size: calc(100% + 200px);
  }

  .block_wrap{
    margin-left: 60px;
  }

  .page_big_title{
    font-size: 55px;
    max-width: 415px;
  }

  .page_start_text{
    font-size: 18px;
    max-width: 415px;
  }

  .page_start_btn{
    margin: 34px auto;
  }

  .page_quest_title{
    font-size: 36px;
  }

  .page_quest_text{
    font-size: 30px;
  }

  .btn{
    height: 55px;
    font-size: 24px;
  }

  .page_quest_buttons_row > div,
  .page_reg_input_row .btn{
    width: 227px;
  }

  .page_quest_checks_row label{
    font-size: 24px;
  }

  .page_quest_checks_row label:before{
    width: 28px;
    height: 28px;
    background-size: 80%;
  }

  .page_reg_text{
    font-size: 30px;
    max-width: 445px;
  }

  .page_reg_input{
    height: 55px;
    font-size: 20px;
  }

  .page_reg_input.input_num{
    width: 300px;
  }

  .page_header_top{
    height: 80px;
  }

  .header_logo{
    height: 42px;
  }

  .page_header_google_btn{
    width: 200px;
    font-size: 12px;
  }

  .page_header_google_btn img{
    width: 24px;
  }

  .step_item{
    height: 34px;
    font-size: 15px;
  }

  .step_item:not(.step_7){
    -webkit-clip-path: polygon(0 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 0% 100%, 13px 50%);
    clip-path: polygon(0 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 0% 100%, 13px 50%);
  }

  .step_item.step_7{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 13px 50%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 13px 50%);
   }

  .step_item:first-child{
    -webkit-clip-path: polygon(0 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 0% 100%);
    clip-path: polygon(0 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 0% 100%);
  }

  .step_item:not(:first-child){
    margin-left: -14px;
  }

  .step_item:not(.step_7) svg{
    width: 11px;
    height: 28px;
    right: 0;
  }

  .step_item.step_7 svg{
    width: 17px;
    height: 17px;
  }

  .step_item.active{
    z-index: 2;
  }

  .step_item.active ~ .step_item{
    z-index: 1;
  }

  .step_item:nth-child(1){
    width: calc(16.9% + 14px);
  }
  .step_item:nth-child(2){
    width: calc(17.1% + 14px);
  }
  .step_item:nth-child(3){
    width: calc(15.1% + 14px);
  }
  .step_item:nth-child(4){
    width: calc(11.5% + 14px);
  }
  .step_item:nth-child(5){
    width: calc(12.6% + 14px);
  }
  .step_item:nth-child(6){
    width: calc(13.3% + 14px);
  }
  .step_item:nth-child(7){
    width: 13.5%;
  }

  .video_bg_wrap{
    height: calc(100% - 114px);
  }

  .page_bg_wrap_anim:before{
    height: 60vh;
  }







  .anim_item{
    border-width: 5px;
  }

  .anim_item.big{
    width: 200px;
  }

  .anim_item.middle{
    width: 150px;
  }

  .anim_item.small{
    width: 110px;
  }

  .anim_item.big .anim_item_name{
    height: 42px;
    font-size: 24px;
  }

  .anim_item.middle .anim_item_name{
    height: 30px;
    font-size: 18px;
  }

  .anim_item.small .anim_item_name{
    height: 24px;
    font-size: 14px;
  }

  .anim_item.i1{
    left: 1%;
  }
  .anim_item.i2{
    left: calc(2% + 200px);
  }
  .anim_item.i3{
    left: calc(3% + 310px);
  }
  .anim_item.i4{
    left: calc(4% + 460px);
  }
  .anim_item.i5{
    left: calc(5% + 660px);
  }
  .anim_item.i6{
    left: calc(6% + 770px);
  }
  .anim_item.i7{
    left: calc(7% + 920px);
  }
  .anim_item.i8{
    left: 345px;
  }
  .anim_item.i9{
    left: calc(3% + 310px);
  }
  .anim_item.i10{
    left: 1%;
  }
  .anim_item.i11{
    left: 1090px;
  }
  .anim_item.i12{
    left: calc(6% + 770px);
  }
  .anim_item.i13{
    left: 1525px;
  }
}

@media screen and (max-width: 1299px){
  .page_bg_item.bg5{
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 1199px){
  .page_quest_text{
    font-size: 24px;
  }

  .page_reg_text{
    font-size: 24px;
  }
}

@media screen and (max-width: 799px){
  .video_bg_wrap:before{
    content: '';
    width: 100%;
    height: 80%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, #71336B 10.12%, rgba(113, 51, 107, 0) 100%);
    z-index: 1;
  }

  .page_header{
    //padding: 20px 30px;
  }

  .page_big_title{
    font-size: 44px;
  }

  .page_start_btn{
    margin: 34px auto;
  }

  .page_quest_title{
    font-size: 36px;
  }

  .btn{
    height: 55px;
    font-size: 24px;
  }

  .page_quest_buttons_row > div,
  .page_reg_input_row .btn{
    width: 227px;
  }

  .page_quest_checks_row label{
    font-size: 24px;
  }

  .page_quest_checks_row label:before{
    width: 28px;
    height: 28px;
    background-size: 80%;
  }

  .page_reg_text{
    //font-size: 30px;
    //max-width: 570px;
  }

  .page_reg_input{
    height: 55px;
    font-size: 20px;
  }

  .page_header_google_btn{
    width: 200px;
    font-size: 12px;
  }

  .page_header_google_btn img{
    width: 24px;
    margin-right: 10px;
  }

  .page_reg_input.input_num{
    width: 300px;
  }
}

@media screen and (max-width: 599px){
  .block_wrap{
    margin-left: auto;
    margin-right: auto;
  }

  .block{
    padding: 0 15px;
  }

  .page_block_1{
    padding: 0 15px;
  }

  .page_header{
    //padding: 20px;
  }

  .page_start_text{
    font-size: 14px;
    margin-top: 19px;
  }

  .page_big_title{
    font-size: 36px;
  }

  .page_start_btn{
    max-width: 340px;
    width: 100%;
    margin: 34px auto;
  }

  .page_quest_buttons_row{
    margin-top: 18px;
  }

  .page_quest_title{
    font-size: 30px;
    margin-bottom: 18px;
  }

  .page_quest_text{
    font-size: 22px;
  }

  .btn{
    height: 40px;
    font-size: 16px;
  }

  .page_quest_buttons_row > div{
    width: 136px;
  }

  .page_reg_input_row .btn{
    width: 262px;
  }

  .page_reg_input_wrap span,
  .page_reg_box_input span{
    font-size: 12px;
    bottom: -17px;
  }

  .page_quest_checks_wrap{
    margin-top: 18px;
    margin-bottom: 0;
  }

  .page_quest_checks_row label{
    font-size: 18px;
  }

  .page_quest_checks_row label:before{
    width: 26px;
    height: 26px;
    margin-right: 14px;
  }

  .page_reg_text{
    font-size: 22px;
  }

  .page_reg_input{
    width: 100%;
    height: 38px;
    font-size: 16px;
  }

  .page_header_google_btn{
    width: max-content;
    font-size: 12px;
    border: none;
    height: auto;
    flex-direction: row-reverse;
  }

  .page_header_google_btn span span{
    display: none;
  }

  .page_header_google_btn img{
    width: 24px;
    margin-right: 0;
    margin-left: 5px;
  }

  .page_reg_input.input_num{
    height: 38px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .page_reg_input_row{
    display: block;
  }

  .page_start_reg_text{
    font-size: 12px;
  }

  .page_start_reg_text span{
    font-size: 14px;
  }

  .page_policy{
    width: 244px;
    padding: 15px 20px;
    font-size: 12px;
    right: 50%;
    transform: translateX(50%);
  }

  .page_policy_close{
    left: calc(100% + 5px);
  }

  .page_quest_checks_row + .page_quest_checks_row{
    margin-top: 10px;
  }

  .page_find_btn{
    width: 100%;
  }

  .page_reg_input_wrap{
    width: 100%;
  }

  .page_reg_input.input_num{
    width: 262px;
  }

  .page_copy{
    width: 100%;
    padding: 4px 0;
    font-size: 9px;
    text-align: center;
  }

  .step_item.active span{
    display: none;
  }

  .page_header_top{
    height: 65px;
    padding: 0 16px;
  }

  .header_logo{
    height: 37px;
  }

  .video_bg_wrap{
    height: calc(100% - 95px);
  }

  .step_item:nth-child(1){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(2){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(3){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(4){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(5){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(6){
    width: calc(13% + 14px);
  }
  .step_item:nth-child(7){
    width: 22%;
    padding-left: 14px;
  }

  .step_item{
    height: 30px;
  }




  .anim_item{
    border-width: 5px;
  }

  .anim_item.big{
    width: 150px;
  }

  .anim_item.middle{
    width: 125px;
  }

  .anim_item.small{
    width: 95px;
  }

  .anim_item.big .anim_item_name{
    height: 32px;
    font-size: 18px;
  }

  .anim_item.middle .anim_item_name{
    height: 24px;
    font-size: 16px;
  }

  .anim_item.small .anim_item_name{
    height: 20px;
    font-size: 12px;
  }

  .anim_item.i1{
    left: 10px;
    animation-delay: 4s;
  }
  .anim_item.i7{
    left: 10px;
    animation-delay: 14s;
  }
  .anim_item.i2{
    left: calc(60%);
    animation-delay: 10s;
  }
  .anim_item.i3{
    left: unset;
    right: 10px;
    animation-delay: 0s;
  }
  .anim_item.i4{
    left: calc(4% + 460px);
    display: none;
  }
  .anim_item.i5{
    left: calc(5% + 660px);
    display: none;
  }
  .anim_item.i6{
    left: calc(6% + 770px);
    display: none;
  }
  .anim_item.i8{
    left: 345px;
    display: none;
  }
  .anim_item.i9{
    left: calc(3% + 310px);
    display: none;
  }
  .anim_item.i10{
    left: 1%;
    display: none;
  }
  .anim_item.i11{
    left: 1090px;
    display: none;
  }
  .anim_item.i12{
    left: calc(6% + 770px);
    display: none;
  }
  .anim_item.i13{
    left: 1525px;
    display: none;
  }

  .video_bg_blur.bl_2{
    width: calc(100% - 13%);
  }
  .video_bg_blur.bl_3{
    width: calc(100% - 26%);
  }
  .video_bg_blur.bl_4{
    width: calc(100% - 39%);
  }
  .video_bg_blur.bl_5{
    width: calc(100% - 52%);
  }
  .video_bg_blur.bl_6{
    width: calc(100% - 65%);
  }
  .video_bg_blur.bl_7{
    width: calc(100% - 78%);
  }
  .video_bg_blur.bl_8{
    width: 0;
  }
}