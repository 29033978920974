.term_popup_wrap{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    padding: 23px 0 0;
    height: 100%;
}

.term_popup_wrap *{
    font-family: "Montserrat", sans-serif !important;
    word-break: break-all;
    white-space: unset !important;
}

.term_popup_title{
    font-weight: 600;
    font-size: 40px;
    color: #00317B;
    text-align: center;
    margin-bottom: 43px;
}

.term_popup_content p{
    font-size: 18px;
    line-height: 1.5;
}

.term_popup_content b{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 30px;
    display: block;
}

.term_popup_content p + b{
    margin-top: 60px;
}

.term_popup_content{
    flex: 1;
    overflow-y: auto;
    padding-left: 80px;
    padding-right: 21px;
    margin-right: 55px;
}

.term_popup_content::-webkit-scrollbar {
    width: 3px;
    background: #F4F4F4;
    border-radius: 3px;
}

.term_popup_content::-webkit-scrollbar-thumb {
    background: #446077;
    border-radius: 3px;
}

@media screen and (max-width: 999px){
    .term_popup_wrap{
        padding: 40px 0 30px;
    }

    .term_popup_content{
        margin-right: 30px;
        padding-left: 40px;
    }
}

@media screen and (max-width: 599px){
    .term_popup_wrap{
        padding: 30px 0;
    }

    .term_popup_title{
        font-size: 28px;
    }

    .term_popup_content{
        padding-left: 15px;
        padding-right: 10px;
        margin-right: 5px;
    }

    .term_popup_content p{
        font-size: 14px;
    }

    .term_popup_content b{
        font-size: 14px;
        margin-bottom: 20px;
    }

    .term_popup_content p + b{
        margin-top: 40px;
    }
}

.term_popup_close{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 5px;
}
.term_popup_close img{
    width: 20px;
}
@media screen and (max-width: 599px){
    .term_popup_close{
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 5px;
    }
    .term_popup_close img{
        width: 14px;
    }
}